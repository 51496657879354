import React from 'react';
import './styles/Footer.css';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer-section">
      <div className="container footer-container">
        <p>&copy; 2024 Zaid's Portfolio</p>
        <div className="social-links">
          <a href="https://github.com/Za43" target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href="https://www.linkedin.com/in/zaid-muqsit-32b196213/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
