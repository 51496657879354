// src/components/Achievements.js
import React from 'react';
import { Element } from 'react-scroll';
import './styles/Section.css';

function Achievements() {
  const achievements = [
    '2023 Vectara Hackathon Winner (Healthcare Category)',
    'AWS Certified Cloud Practitioner',
    'Dean’s List With Distinction (4x)',
    'Codepath: Intro to Cybersecurity Certificate',
    'Codepath: Advanced Technical Interview Prep Certificate',
    // Add more achievements as needed
  ];

  return (
    <Element name="achievements" className="section">
      <div className="container">
        <h2>Achievements</h2>
        <div className="section-content achievement-grid">
          {achievements.map((ach, index) => (
            <div className="achievement-item" key={index}>
              <h3>{ach}</h3>
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default Achievements;
