// src/components/Projects.js
import React from 'react';
import { Element } from 'react-scroll';
import './styles/Section.css';

function Projects() {
  const projects = [
    {
      name: 'Research Connect: LinkedIn for Researchers',
      description: [
        'Engineered a full-stack collaborative platform using MongoDB, NodeJS, NextJS, and ReactJS, to enhance the research community’s engagement with features like secure authentication and community interactions.',
        'Implemented advanced functionalities including real-time notifications, PDF uploads, and a dynamic news feed with comment interactions, achieving operational community features and user content management.',
      ],
      link: 'https://github.com/coffeewithone/research-connect',
    },
    {
      name: 'BrainMRI AI',
      description: [
        'Pioneered a transformative diagnostic tool that synergizes Vectara’s semantic search with webkitSpeechRecognition API for voice-activated radiological analysis, enhancing the accuracy and speed of brain tumor diagnoses.',
        'Spearheaded development of an AI-powered, NextJS-based interface for radiologists, featuring hands-free operation and advanced data retrieval, which streamlines case reviews and medical education with access to real MRI cases.',
      ],
      link: 'https://github.com/Za43/BrainMRI-AI',
    },
    {
        name: 'Spotify Song Analysis',
        description: [
          'Leveraged Spotify\'s Web API to automate the extraction of song data for over 10,000 tracks, streamlining the analysis pipeline and reducing manual data collection time by 80%.',
          'Utilized R\'s ggplot2 and dplyr packages to conduct in-depth analyses on song popularity trends, creating interactive visualizations that uncovered key patterns in genre preferences and track popularity across various demographics.',
          'Engineered custom features from audio metrics (e.g., danceability, tempo, and energy) and built predictive models using R’s caret package to estimate a song\'s popularity, achieving 85% accuracy in testing.'
        ],
        link: 'https://github.com/Za43/Spotify-Song-Analysis',
      }
    // Add more projects as needed
  ];

  return (
    <Element name="projects" className="section">
      <div className="container">
        <h2>Projects</h2>
        <div className="section-content project-grid">
          {projects.map((proj, index) => (
            <div className="project-item" key={index}>
              {/* Project Name and GitHub Link */}
              <div className="project-header">
                <h3>{proj.name}</h3>
                <a
                  href={proj.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="github-link"
                >
                  GitHub Repo
                </a>
              </div>

              {/* Description */}
              <ul>
                {proj.description.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default Projects;
