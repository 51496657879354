// src/components/Experience.js
// src/components/Experience.js
import React from 'react';
import { Element } from 'react-scroll';
import './styles/Section.css';

function Experience() {
    const experiences = [
        {
          role: 'Software Engineering Intern',
          company: 'Capital One — Card Tech',
          duration: 'May 2024 - August 2024',
          description: [
            'Designed React frontend to track and report line transfer testing, boosting transparency and real-time monitoring.',
            'Developed backend Python code to automate testing flow, identifying API failures and cross-referencing AWS CloudWatch logs for precise error pinpointing.',
            'Reduced the testing process from hours to just minutes by automating log analysis and error matching, saving approximately 98% of the time typically spent on manual testing.',
          ],
        },
        {
            role: 'Systems Engineering Intern',
            company: 'CS Plus — Efficient Application Level Networking, Duke University',
            duration: 'May 2023 - August 2023',
            description: [
              'Enhanced RPC efficiency by implementing RPC-as-a-managed-service in Rust, reducing marshaling steps.',
              'Constructed a Python front-end using pyo3 to wrap Rust classes and functions.',
              'Final product interacts with the Rust back-end and adds a 4X speed boost on gRPC’s Python implementation.',
            ],
          },
          {
            role: 'Machine Learning Intern',
            company: 'Data Plus — Understanding Electric Consumption Profiles, Duke University',
            duration: 'May 2022 - July 2022',
            description: [
              'Addressed the topic of efficiently and privately breaking down electricity profiles with data from a Uruguay energy company by researching, analyzing, building, and testing various models.',
              'Constructed complex models, like a Bidirectional LSTM with PyTorch, to aid in promoting sustainable practices.',
              'Final model disaggregated data with over 95% accuracy.',
            ],
          },
          {
            role: 'CS210 Undergraduate Teaching Assistant',
            company: 'Computer Systems and Arch — Duke University',
            duration: 'August 2022 - Present',
            description: [
            'Guided students in understanding low-level programming concepts in C, including memory management, concurrency, and system calls.',
            'Assisted students with debugging complex systems code, helping them master tools like GDB and Valgrind.',
            'Led discussion sections to reinforce concepts from class, focusing on practical applications such as multi-threading and process synchronization.',
            ],
          }
      ];
    
    
  return (
    <Element name="experience" className="section">
      <div className="container">
        <h2>Experience</h2>
        <div className="section-content experience-grid">
          {experiences.map((exp, index) => (
            <div className="experience-item" key={index}>
              {/* Role Title and Duration */}
              <div className="role-duration">
                <h3>{exp.role}</h3>
                <span className="duration">{exp.duration}</span>
              </div>

              {/* Company Name */}
              <span className="company">{exp.company}</span>

              {/* Description */}
              <ul>
                {exp.description.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default Experience;