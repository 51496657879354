// src/components/Introduction.js
import React from 'react';
import { Element, Link as ScrollLink } from 'react-scroll';
import './styles/Section.css';
import ZMHeadshot from './ZMHeadshot.png'; // Ensure the image is in the same directory

function Introduction() {
  return (
    <Element name="introduction" className="section intro-section">
      <div className="container intro-container">
        {/* Introduction Text */}
        <div className="intro-text">
          <h1>Hi, I'm Zaid!</h1>
          <div className="intro-details">
            <p>I'm currently a fourth year student at Duke University studying Computer Science and Statistics.</p>
            <div className="button-group">
              <a href="mailto:zm73@duke.com" className="contact-button">Contact Me</a>
              <a
                href="https://drive.google.com/file/d/1isiLrjI8o0unBlOYpUhJuKQzHvbhaf-q/view?usp=sharing"
                className="resume-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Resume
              </a>
            </div>
            <p className="scroll-down-text">
              Scroll down or{' '}
              <ScrollLink to="experience" smooth={true} duration={500} offset={-50}>
                click here
              </ScrollLink>{' '}
              to learn more about me and view my experience, projects, and achievements.
            </p>
          </div>
        </div>
        {/* Introduction Image */}
        <div className="intro-image">
          <img src={ZMHeadshot} alt="Zaid" />
        </div>
      </div>
    </Element>
  );
}

export default Introduction;
