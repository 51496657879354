import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './styles/Navbar.css';

function Navbar() {
  const [activeSection, setActiveSection] = useState('introduction');

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  return (
    <nav className="navbar">
      <div className="container nav-container">
        <div className="logo">
          <a href="/">Zaid's Portfolio</a>
        </div>
        <div className="nav-links">
          {['introduction', 'experience', 'projects', 'achievements'].map((section) => (
            <Link
              key={section}
              to={section}
              spy={true}
              smooth={true}
              duration={500}
              offset={-50}
              className={activeSection === section ? 'active' : ''}
              onSetActive={handleSetActive}
            >
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
