import React from 'react';
import Navbar from './components/Navbar';
import Introduction from './components/Introduction';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Achievements from './components/Achievements';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Navbar />
      <Introduction />
      <Experience />
      <Projects />
      <Achievements />
      <Footer />
    </>
  );
}

export default App;
